@tailwind base;
@tailwind components;
@tailwind utilities;

.active {
  @apply text-[#fa6f3c] font-bold text-base;
}

@layer base {
  .buttonSm {
    @apply bg-[#fa6f3c] text-white font-medium px-10 lg:py-1 2xl:py-2 hover:bg-transparent hover:border-2 border-[#fa6f3c] border-2 duration-200 rounded-tr-xl rounded-bl-xl;
  }
  .button {
    @apply bg-[#fa6f3c] text-white font-medium px-10 lg:py-2 2xl:py-3 hover:bg-transparent hover:border-2 border-[#fa6f3c] border-2 duration-200 rounded-tr-xl rounded-bl-xl text-lg;
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 3px gainsboro;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fa6f3c;
  border-radius: 5px;
}

.custom-border::after {
  position: absolute;
  content: "";
  width: 180px;
  height: 2px;
  background: #fa6f3c;
  bottom: -40px;
  left: 620px;
}
.custom-border2::after {
  position: absolute;
  content: "";
  width: 136px;
  height: 2px;
  background: #fa6f3c;
  bottom: -22px;
  left: 279px;
}

.custom-link::before {
  position: absolute;
  content: "";
  bottom: -10px;
  left: 0;
  height: 3px;
  width: 0;
  background: #fa6f3c;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.custom-link:hover::before {
  width: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  color: #fa6f3c;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
